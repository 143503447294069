import { useState, useEffect } from "react";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {
  createLCDClient,
  WalletController,
} from "@terra-money/wallet-controller";
import { Coin, Coins, Fee, isTxError } from "@terra-money/terra.js";

if (global.window) window.ethers = ethers;

export const ADDRESS_ZERO = "0x" + "0".repeat(40);
export const ZERO_BYTES32 = "0x" + "0".repeat(64);

export const parseUnits = ethers.utils.parseUnits;
export const formatUnits = ethers.utils.formatUnits;

export const networkNames = {
  1: "Ethereum",
  5: "Goerli",
  137: "Polygon",
  250: "Fantom",
  324: "zkSync Era",
  "terra-classic": "Terra (Classic)",
  "terra-testnet": "Terra (Testnet)",
};

export const terraGasPriceApi = {
  "terra-classic": "https://fcd.terra.dev/v1/txs/gas_prices",
  "terra-testnet": "https://bombay-fcd.terra.dev/v1/txs/gas_prices",
};

const rpcUrl = process.env.VITE_RPC_URL || "https://rpc.ankr.com/eth";

let terraWalletSubscription;
let terraWalletController;
if (global.window) {
  try {
    terraWalletController = new WalletController({
      walletConnectChainIds: {
        1: {
          chainID: "columbus-5",
          lcd: "https://columbus-lcd.terra.dev",
          mantle: "https://columbus-mantle.terra.dev",
          name: "classic",
          walletconnectID: 2,
        },
      },
    });
  } catch (e) {
    console.error("terra", e);
  }
}

let listeners = [];
let state = {
  ready: false,
  networkId: 1,
  address: null,
  signer: null,
  provider: new ethers.providers.JsonRpcProvider(rpcUrl),
};

if (global.window) {
  window.getState = () => state;
}

export async function connectWalletEthereum(wallet = "metamask") {
  if (wallet === "walletconnect") {
    const wcProvider = new WalletConnectProvider({
      rpc: {
        1: rpcUrl,
        137: "https://polygon-rpc.com/",
        250: "https://rpc.fantom.network",
      },
    });
    await wcProvider.enable();
    state.provider = new ethers.providers.Web3Provider(wcProvider);
  }
  if (wallet === "metamask") {
    if (!window.ethereum) throw new Error("No ethereum wallet installed!");
    await window.ethereum.request({
      method: "eth_requestAccounts",
      params: [],
    });
    state.provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  }

  const signer = state.provider.getSigner();
  const address = await signer.getAddress();
  const networkId = (await state.provider.getNetwork()).chainId;
  setGlobalState({
    walletModalOpen: false,
    ready: true,
    signer,
    address,
    networkId,
  });
  window.localStorage.setItem("connectedWallet", wallet);

  async function updateNetworkAndAddress() {
    const signer = state.provider.getSigner();
    const address = await signer.getAddress();
    const networkId = (await state.provider.getNetwork()).chainId;
    setGlobalState({ signer, address, networkId });
  }
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", updateNetworkAndAddress);
    window.ethereum.on("networkChanged", updateNetworkAndAddress);
  }
}
export async function connectWalletTerra(wallet = "terrastation") {
  await terraWalletController.connect(
    wallet === "terrawalletconnect" ? "WALLETCONNECT" : "EXTENSION"
  );
  terraWalletSubscription = terraWalletController.states().subscribe({
    next: async (value) => {
      console.log("terra wallet state", value);
      if (!value.network || !value.wallets) return;
      const networkId = "terra-" + value.network.name;
      let address = "";
      if (value.wallets && value.wallets[0]) {
        address = value.wallets[0].terraAddress;
      }
      const lcd = createLCDClient({
        network: value.network,
        gasPrices: [new Coin("uusd", 0.15)],
        gasAdjustment: 1.6,
        gas: 750000,
        isClassic: true,
      });
      const gasRes = await (await fetch(terraGasPriceApi[networkId])).json();
      const terraTaxRate = await lcd.treasury.taxRate();
      const terraTaxCapUusd = await lcd.treasury.taxCap("uusd");
      setGlobalState({
        walletModalOpen: false,
        ready: true,
        lcd,
        wc: terraWalletController,
        gasPriceUusd: gasRes.uusd,
        gasPriceLuna: gasRes.uluna,
        terraTaxRate: parseUnits(terraTaxRate.toString(), 18),
        terraTaxCapUusd: parseUnits(terraTaxCapUusd.amount.toString(), 8),
        address,
        networkId,
      });
      window.localStorage.setItem("connectedWallet", wallet);
    },
  });
}

export function disconnectWallet() {
  if (terraWalletSubscription) {
    terraWalletSubscription.unsubscribe();
    terraWalletSubscription = null;
  }
  window.localStorage.setItem("connectedAddress", "");
  setGlobalState({
    networkId: 1,
    address: null,
    signer: null,
    provider: new ethers.providers.JsonRpcProvider(rpcUrl),
  });
}

if (global.window) {
  const wallet = window.localStorage.getItem("connectedWallet");

  if (wallet && wallet.startsWith("terra")) {
    setTimeout(() => connectWalletTerra(wallet), 2500);
  } else {
    connectWalletEthereum(wallet);
  }
}

export function useGlobalState() {
  const [lastState, setLastState] = useState(state);

  useEffect(() => {
    const handler = () => {
      setLastState(state);
    };
    listeners.push(handler);
    return () => listeners.splice(listeners.indexOf(handler), 1);
  }, []);

  return lastState;
}

export function setGlobalState(newState) {
  state = Object.assign(Object.assign({}, state), newState);
  listeners.forEach((l) => l());
}

export function terraTax(amount, taxRate, taxCap) {
  return bnMin(amount.mul(taxRate).div(parseUnits("1", 18)), taxCap);
}

export function dateForBlock(block, currentBlock) {
  return new Date(Date.now() - (currentBlock - block) * 13250);
}

export function formatAddress(a) {
  return a.slice(0, 6) + "..." + a.slice(-4);
}

export function formatDate(dateLike) {
  if (dateLike instanceof ethers.BigNumber) {
    dateLike = dateLike.toNumber() * 1000;
  }
  const d = new Date(dateLike);
  if (d.getTime() === 0) return "N/A";
  const pad = (s) => ("0" + s).slice(-2);
  return [
    d.getFullYear() + "-",
    pad(d.getMonth() + 1) + "-",
    pad(d.getDate()) + " ",
    pad(d.getHours()) + ":",
    pad(d.getMinutes()),
  ].join("");
}

export function formatMDY(dateLike) {
  if (dateLike._isBigNumber) {
    dateLike = dateLike.toNumber() * 1000;
  }
  const d = new Date(dateLike);
  if (d.getTime() === 0) return "N/A";
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}

export function formatNumber(n, decimals = 2, units = 18) {
  if (n instanceof ethers.BigNumber || (n && n._isBigNumber)) {
    n = parseFloat(ethers.utils.formatUnits(n, units));
  }
  n = n || 0;
  n = n.toFixed(decimals);
  if (n.endsWith((0).toFixed(decimals).slice(1))) {
    n = n.split(".")[0];
  }
  let start = n.indexOf(".");
  if (start === -1) start = n.length;
  for (let i = start - 3; i > 0; i -= 3) {
    n = n.slice(0, i) + "," + n.slice(i);
  }
  return n;
}

export function formatErrorMessage(e) {
  const message =
    e?.data?.message ||
    e?.error?.data?.originalError?.message ||
    e.message ||
    String(e);
  if (message.includes("sale not ended")) {
    return "Claims not started yet";
  }
  if (message.includes("User denied transaction signature")) {
    return "You cancelled the transaction";
  }
  if (message.includes("user rejected transaction")) {
    return "You cancelled the transaction";
  }
  if (message.includes("ERC20: transfer amount exceeds balance")) {
    return "Token balance too low in contract";
  }
  if (message.includes("cannot estimate gas; transaction")) {
    if (!e.error) return message;
    return e.error.data.message.replace("execution reverted: ", "");
  }
  return message;
}

export async function runTransaction(callPromise, setLoading, setError) {
  try {
    setError("");
    setLoading("Waiting for confirmation...");
    const tx = await callPromise;
    setLoading("Transaction pending...");
    await tx.wait();
  } catch (err) {
    console.error("runTransaction:", err);
    setError(formatErrorMessage(err));
    throw err;
  } finally {
    setLoading("");
  }
}

export async function runTransactionTerra(params, setLoading, setError) {
  try {
    setError("");
    setLoading("Transaction pending...");
    params.isClassic = true;
    params.gasPrices = new Coins([new Coin("uusd", state.gasPriceUusd)]);
    //console.log('gas', state.gasPriceUusd)
    //params.fee = new Fee(750000, { uusd: '2000000' });
    const result = await state.wc.post(params);
    console.log("result", result);
    if (isTxError(result)) {
      console.error("error", result.code, result.raw_log);
      const error = `Error: ${result.code}: ${result.codespace}`;
      setError(error);
      throw new Error(error);
    }
  } catch (err) {
    console.error("runTransactionTerra:", err, JSON.stringify(err));
    setError(String(err));
    throw err;
  } finally {
    setLoading("");
  }
}

export function bn(n) {
  return ethers.BigNumber.from(n);
}

export function bnMin(a, b) {
  return a.gt(b) ? b : a;
}

export function bnMax(a, b) {
  return a.gt(b) ? a : b;
}

export function cannonicalAddress(address) {
  if (address.startsWith("0x")) {
    return ethers.utils.getAddress(address.toLowerCase());
  }
  return address;
}
