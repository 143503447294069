import React from "react";
import ReactDOM from "react-dom/client";
import Sale from "./Sale";

import cssText from "bundle-text:./index.scss";
let style = document.createElement("style");
style.textContent = cssText;
document.body.appendChild(style);

const idoElement = document.getElementById("idoappdetails");

if (idoElement) {
  const nameEl = document.getElementById("idoname");
  const tokenEl = document.getElementById("idotoken");
  const paymentTokenEl = document.getElementById("idopaymenttoken");
  const typeEl = document.getElementById("idotype");
  const networkIdEl = document.getElementById("idonetworkid");
  const addressEl = document.getElementById("idocontract");
  const paymentTokenAddressEl = document.getElementById(
    "idopaymenttokenaddress"
  );

  const info = {
    name: nameEl && nameEl.innerText,
    token: tokenEl && tokenEl.innerText,
    paymentToken: paymentTokenEl && paymentTokenEl.innerText,
    type: typeEl && typeEl.innerText,
    networkId: networkIdEl && parseInt(networkIdEl.innerText),
    address: addressEl && addressEl.innerText,
    paymentTokenAddress:
      paymentTokenAddressEl && paymentTokenAddressEl.innerText,
    paymentDecimals: 6,
    paymentDecimalsShown: 2,
  };
  if (info.address.startsWith("terra")) {
    info.networkId = "terra-classic";
    info.paymentDecimals = 12;
  }

  ReactDOM.createRoot(idoElement).render(
    <React.StrictMode>
      <Sale info={info} />
    </React.StrictMode>
  );
}
