import { useState, useEffect } from "react";
import { MsgExecuteContract } from "@terra-money/terra.js";

import {
  networkNames,
  disconnectWallet,
  connectWalletEthereum,
  connectWalletTerra,
  runTransaction,
  runTransactionTerra,
  useGlobalState,
  setGlobalState,
  formatNumber,
  parseUnits,
  formatUnits,
  formatAddress,
  cannonicalAddress,
  bn,
  bnMin,
  bnMax,
} from "./utils";

const userScoreApiEndpoint =
  "https://thorstarter-tiers-api.herokuapp.com/user-signed-score";

import metamaskUrl from "./images/wallets/metamask.png";
import terrastationUrl from "./images/wallets/terrastation.png";
import trustUrl from "./images/wallets/trust.png";
import walletconnectUrl from "./images/wallets/walletconnect.png";
import xdefiUrl from "./images/wallets/xdefi.png";

export default function Sale({ info }) {
  const state = useGlobalState();
  const [saleState, setSaleState] = useState();
  const [userState, setUserState] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");

  function getSaleContract() {
    const abi = {
      fcfs: [
        "function getParams() view returns (uint, uint, uint, uint, uint, uint, uint, bool, bool)",
        "function userInfo(address) view returns (uint, bool)",
        "function getOfferingAmount(address) view returns (uint)",
        "function harvest(bool)",
      ],
      fcfssimple: [
        "function getParams() view returns (uint, uint, uint, uint, uint, uint, bool, bool)",
        "function getUserInfo(address) view returns (uint, uint, uint, uint)",
        "function harvest()",
        "function deposit(uint)",
      ],
      batch: [
        "function getParams() view returns (uint, uint, uint, uint, uint, uint, bool, bool)",
        "function userInfo(address) view returns (uint, bool, bool)",
        "function getOfferingAmount(address) view returns (uint)",
        "function getRefundingAmount(address) view returns (uint)",
        "function harvestTokens()",
        "function harvestRefund()",
        "function harvestAll()",
      ],
      dutch: [
        "function getParams() view returns (uint, uint, uint, uint, uint, uint, uint, uint, uint, bool, bool)",
        "function userInfo(address) view returns (uint, bool)",
        "function harvestTokens()",
      ],
      tiers: [
        "function getParams() view returns (uint, uint, uint, uint, uint, bool, bool)",
        "function getUserInfo(address) view returns (uint, uint, uint, uint)",
        "function harvest()",
      ],
      share: [
        "function totalScore() view returns (uint)",
        "function finalizedBaseAmount() view returns (uint)",
        "function getParams() view returns (uint, uint, uint, uint, uint, bool, bool, uint)",
        "function getUserInfo(address) view returns (uint, uint, uint, uint, uint, bool)",
        "function harvest()",
        "function harvestRefund()",
        "function harvestAll()",
      ],
    }[info.type];
    return new ethers.Contract(
      info.address,
      abi,
      state.signer || state.provider
    );
  }

  async function fetchData() {
    if (!state.address) return;
    if (state.networkId !== info.networkId) return;
    if (info.type === "tiers-terra") {
      const userState = await state.lcd.wasm.contractQuery(info.address, {
        user_state: { user: state.address, now: (Date.now() / 1000) | 0 },
      });
      const idoState = await state.lcd.wasm.contractQuery(info.address, {
        state: {},
      });
      const raising = parseUnits(idoState.raising_amount, 12);
      const offering = parseUnits(idoState.offering_amount, 12);
      const comitted = parseUnits(idoState.total_amount, 12);
      const amount = parseUnits(userState.amount, 12);
      const owed = parseUnits(userState.owed, 12);
      const claimable = parseUnits(userState.claimable, 12);
      const claimed = parseUnits(userState.claimed, 12);
      const claimedTokens = owed.sub(claimed).eq("0");
      setUserState({ amount, claimedTokens, claimable, claimed, owed });
      setSaleState({
        timestamp: (Date.now() / 1000) | 0,
        start: new Date(idoState.start_time * 1000),
        end: new Date(idoState.end_time * 1000),
        raising: raising,
        offering: offering,
        comitted: comitted,
        paused: false,
        price: raising.mul(parseUnits("1")).div(offering),
        finalized: idoState.finalized,
      });
      return;
    }

    if (info.type === "fcfs") {
      const sale = getSaleContract();
      const params = await sale.getParams();
      const userInfo = await sale.userInfo(state.address);
      const amount = userInfo[0];
      const claimedTokens = userInfo[1];
      const owed = await sale.getOfferingAmount(state.address);
      setUserState({ amount, claimedTokens, owed });
      setSaleState({ finalized: params[8] });
    } else if (info.type === "batch") {
      const sale = getSaleContract();
      const params = await sale.getParams();
      const userInfo = await sale.userInfo(state.address);
      const amount = userInfo[0];
      const claimedTokens = userInfo[2];
      const claimedRefund = userInfo[1];
      const owed = await sale.getOfferingAmount(state.address);
      const refund = await sale.getRefundingAmount(state.address);
      setUserState({ amount, claimedTokens, claimedRefund, owed, refund });
      setSaleState({ finalized: params[7] });
    } else if (info.type === "dutch") {
      const sale = getSaleContract();
      const params = await sale.getParams();
      const userInfo = await sale.userInfo(state.address);
      const amount = userInfo[0];
      const claimedTokens = userInfo[1];
      const owed = userInfo[0].mul(parseUnits("1")).div(params[7]);
      setUserState({ amount, claimedTokens, owed });
      setSaleState({ finalized: params[10] });
    } else if (info.type === "tiers") {
      const sale = getSaleContract();
      const params = await sale.getParams();
      const userInfo = await sale.getUserInfo(state.address);
      const amount = userInfo[0];
      const claimedTokens = userInfo[3].sub(userInfo[1]).eq("0");
      const claimable = userInfo[3];
      const claimed = userInfo[1];
      const owed = userInfo[2];
      setUserState({ amount, claimedTokens, claimable, claimed, owed });
      setSaleState({ finalized: params[6], end: new Date(1659386895600) });
    } else if (info.type === "share") {
      const lastBlock = await getState().provider.getBlock(-1);
      const paymentToken = new ethers.Contract(
        info.paymentTokenAddress,
        [
          "function decimals() view returns (uint)",
          "function balanceOf(address) view returns (uint)",
        ],
        state.provider
      );
      const sale = getSaleContract();
      const paymentDecimals = await paymentToken.decimals();
      const totalScore = await sale.totalScore();
      const finalizedBaseAmount = await sale.finalizedBaseAmount();
      const params = await sale.getParams();
      const userInfo = await sale.getUserInfo(state.address);
      let body = {};
      try {
        const res = await fetch(
          `${userScoreApiEndpoint}?address=${
            state.address
          }&deadline=${params[1].toNumber()}`
        );
        body = await res.json();
      } catch (err) {
        console.error("Failed to fetch score an signature", err);
      }
      setUserState({
        balance: await paymentToken.balanceOf(state.address),
        amount: userInfo[0],
        claimedTokens: userInfo[3].sub(userInfo[1]).eq("0"),
        claimable: userInfo[3],
        claimed: userInfo[1],
        owed: userInfo[2],
        refund: userInfo[4],
        claimedRefund: userInfo[5],
        score: bn(body.score || 0),
        signature: body.signature,
      });
      setSaleState({
        timestamp: lastBlock.timestamp,
        start: new Date(params[0].toNumber() * 1000),
        end: new Date(params[1].toNumber() * 1000),
        raising: params[2],
        offering: params[3],
        comitted: params[4],
        paused: params[5],
        finalized: params[6],
        price: params[2].mul(parseUnits("1")).div(params[3]),
        paymentDecimals: paymentDecimals,
        totalScore: totalScore,
        finalizedBaseAmount: finalizedBaseAmount,
      });
    } else if (info.type === "fcfssimple") {
      const forge = new ethers.Contract(
        "0x2D23039c1bA153C6afcF7CaB9ad4570bCbF80F56",
        ["function balanceOf(address) view returns (uint)"],
        new ethers.providers.JsonRpcProvider("https://rpc.fantom.network")
      );
      const userInForge = (await forge.balanceOf(state.address)).gt(0);
      const paymentToken = new ethers.Contract(
        info.paymentTokenAddress,
        [
          "function decimals() view returns (uint)",
          "function balanceOf(address) view returns (uint)",
        ],
        state.provider
      );
      const paymentDecimals = await paymentToken.decimals();
      const sale = getSaleContract();
      const params = await sale.getParams();
      const userInfo = await sale.getUserInfo(state.address);
      const amount = userInfo[0];
      const claimedTokens = userInfo[3].sub(userInfo[1]).eq("0");
      const claimable = userInfo[3];
      const claimed = userInfo[1];
      const owed = userInfo[2];
      setUserState({
        balance: await paymentToken.balanceOf(state.address),
        amount,
        claimedTokens,
        claimable,
        claimed,
        owed,
      });
      setSaleState({
        timestamp: Date.now(),
        start: new Date(params[0].toNumber() * 1000),
        end: new Date(params[1].toNumber() * 1000),
        raising: params[2],
        offering: params[3],
        comitted: params[4],
        allocation: params[5].mul(userInForge ? 100 : 50).div(100),
        paused: params[6],
        finalized: params[7],
        price: params[2].mul(parseUnits("1")).div(params[3]),
        paymentDecimals: paymentDecimals,
      });
    }
  }

  useEffect(() => {
    fetchData();
    const handle = setInterval(fetchData, 10000);
    setTimeout(() => clearInterval(handle), 60 * 60 * 1000); // Stop after 1 hour
    return () => clearInterval(handle);
  }, [state.networkId, state.address]);

  async function callSaleMethod(method, ...args) {
    const sale = getSaleContract();
    const call = sale[method](...args);
    await runTransaction(call, setLoading, setError);
    fetchData();
  }

  async function onHarvest() {
    if (
      userState.refund &&
      userState.refund.gt("0") &&
      !userState.claimedRefund
    ) {
      callSaleMethod("harvestAll");
      return;
    }

    if (info.type === "tiers-terra") {
      try {
        await runTransactionTerra(
          {
            msgs: [
              new MsgExecuteContract(state.address, info.address, {
                harvest: {},
              }),
            ],
          },
          setLoading,
          setError
        );
      } catch (e) {
        console.log("err", e);
      }
      fetchData();
      setTimeout(fetchData, 10000);
    } else if (info.type == "fcfs") {
      callSaleMethod("harvest", false);
    } else if (info.type === "batch") {
      callSaleMethod("harvestTokens");
    } else if (info.type === "dutch") {
      callSaleMethod("harvestTokens");
    } else {
      callSaleMethod("harvest");
    }
  }

  async function executeDeposit(parsedAmount) {
    const paymentToken = new ethers.Contract(
      info.paymentTokenAddress,
      [
        "function allowance(address, address) view returns (uint256)",
        "function approve(address, uint256)",
      ],
      state.signer
    );

    const allowance = await paymentToken.allowance(state.address, info.address);
    if (allowance.lt(parsedAmount)) {
      const call = paymentToken.approve(info.address, parsedAmount);
      try {
        await runTransaction(call, setLoading, setError);
        await new Promise((resolve) => setTimeout(resolve, 3000));
      } catch (e) {
        return;
      }
    }

    let call;
    if (info.type === "fcfssimple") {
      const sale = new ethers.Contract(
        info.address,
        ["function deposit(uint)"],
        state.signer
      );
      call = sale.deposit(parsedAmount);
    } else {
      const sale = new ethers.Contract(
        info.address,
        ["function deposit(uint, uint, bytes)"],
        state.signer
      );
      call = sale.deposit(
        parsedAmount,
        userState.score,
        "0x" + userState.signature
      );
    }
    try {
      await runTransaction(call, setLoading, setError);
    } catch (e) {}
  }

  async function onDeposit() {
    setError("");
    try {
      const parsedAmount = parseUnits(
        amount.replace(/[^0-9\.]/g, ""),
        saleState.paymentDecimals
      );
      setAmount(formatUnits(parsedAmount, saleState.paymentDecimals));
      if (
        saleState.allocation &&
        userState &&
        userState.amount.add(parsedAmount).gt(saleState.allocation)
      ) {
        setError("Deposit over allocation");
        return;
      }

      if (info.type === "tiers-terra") {
        try {
          await runTransactionTerra(
            {
              msgs: [
                new MsgExecuteContract(
                  state.address,
                  info.address,
                  {
                    [Date.now() < saleState.end.getTime()
                      ? "deposit"
                      : "deposit_fcfs"]: {
                      allocation: userInfo.allocationStr,
                      proof: userInfo.proof,
                    },
                  },
                  [
                    new Coin(
                      "uusd",
                      parsedAmount.div("1000000000000").toString()
                    ),
                  ]
                ),
              ],
            },
            setLoading,
            setError
          );
        } catch (e) {
          console.log("err", e);
        }
      } else {
        await executeDeposit(parsedAmount);
      }
      setAmount("");
      fetchData();
    } catch (err) {
      console.error(err);
      setError("Invalid number provided");
    }
  }

  function onDepositMax() {
    let actualBalance = userState.balance;
    if (info.paymentToken === "UST") {
      actualBalance = actualBalance.sub(parseUnits("5"));
    }
    setAmount(
      formatUnits(actualBalance, saleState.paymentDecimals).replace(/,/g, "")
    );
  }

  let component = null;
  if (!state.address) {
    component = (
      <Button
        className="ts-button"
        onClick={() => setGlobalState({ walletModalOpen: true })}
      >
        Connect Wallet
      </Button>
    );
  } else if (info.networkId != state.networkId) {
    component = (
      <div className="ts-message">
        Wrong network (<b>{networkNames[state.networkId]}</b>), switch to{" "}
        <b>{networkNames[info.networkId]}</b> to participate in this sale
      </div>
    );
  } else if (!saleState) {
    component = <div className="ts-message">Loading...</div>;
  } else if (saleState.finalized || Date.now() > saleState.end.getTime()) {
    component = (
      <div>
        {userState ? (
          <>
            <div className="ts-data-row">
              <div>Deposited</div>
              <div>
                {formatNumber(
                  userState.amount,
                  info.paymentDecimalsShown,
                  saleState.paymentDecimals || info.paymentDecimals
                )}{" "}
                <span className="ts-text-gray6">{info.paymentToken}</span>
              </div>
            </div>
            <div className="ts-data-row">
              <div>
                {userState.claimed
                  ? "Total Owed"
                  : userState.claimedTokens
                  ? "Collected"
                  : "Owed"}
              </div>
              <div>
                {formatNumber(userState.owed)}{" "}
                <span className="ts-text-gray6">{info.token}</span>
              </div>
            </div>
            {userState.claimed && info.token !== "MINT" ? (
              <div className="ts-data-row">
                <div>Collected</div>
                <div>
                  {formatNumber(userState.claimed)}{" "}
                  <span className="ts-text-gray6">{info.token}</span>
                </div>
              </div>
            ) : null}
            {userState.claimable && info.token !== "MINT" ? (
              <div className="ts-data-row">
                <div>Vested</div>
                <div>
                  {formatNumber(userState.claimable)}{" "}
                  <span className="ts-text-gray6">{info.token}</span>
                </div>
              </div>
            ) : null}
            {userState.refund && userState.refund.gt("0") ? (
              <div className="ts-data-row">
                <div>Refund {userState.claimedRefund ? "(Claimed)" : ""}</div>
                <div>
                  {formatNumber(userState.refund)}{" "}
                  <span className="ts-text-gray6">{info.paymentToken}</span>
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        {error ? <div className="ts-error">{error}</div> : null}

        {userState &&
        userState.amount.gt("0") &&
        !userState.claimedTokens &&
        info.token !== "MINT" ? (
          <Button
            onClick={onHarvest}
            disabled={
              !saleState.finalized ||
              (userState.claimable && userState.claimable.eq(userState.claimed))
            }
          >
            {loading
              ? "Loading..."
              : userState.claimable && userState.claimable.eq(userState.claimed)
              ? "No vested tokens"
              : !saleState.finalized
              ? "Soon..."
              : userState.refund &&
                userState.refund.gt("0") &&
                !userState.claimedRefund
              ? "Claim Tokens & Refund"
              : "Claim Tokens"}
          </Button>
        ) : null}
      </div>
    );
  } else {
    component = (
      <div>
        <div className="ts-data-row">
          <div>Balance: </div>
          <div className="ts-text-primary5">
            {formatNumber(
              userState.balance,
              info.paymentDecimalsShown,
              saleState.paymentDecimals
            )}{" "}
            {info.paymentToken}
          </div>
        </div>
        {error ? <div className="ts-error">{error}</div> : null}
        <div className="ts-input-with-link">
          <input
            className="ts-input"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <a onClick={onDepositMax}>Max</a>
        </div>
        <Button onClick={onDeposit} disabled={loading} className="ts-mb-2">
          {loading ? "Loading..." : "Deposit"}
        </Button>
        <div className="ts-data-row">
          <div>Deposited</div>
          <div>
            {formatNumber(
              userState.amount,
              info.paymentDecimalsShown,
              saleState.paymentDecimals || info.paymentDecimals
            )}{" "}
            <span className="ts-text-gray6">{info.paymentToken}</span>
          </div>
        </div>
        {userState.owed ? (
          <div className="ts-data-row">
            <div>Owed</div>
            <div>
              {formatNumber(userState.owed)}{" "}
              <span className="ts-text-gray6">{info.token}</span>
            </div>
          </div>
        ) : null}
        {saleState.allocation ? (
          <div className="ts-data-row">
            <div>Allocation</div>
            <div>
              {formatNumber(
                saleState.allocation,
                info.paymentDecimalsShown,
                saleState.paymentDecimals || info.paymentDecimals
              )}{" "}
              <span className="ts-text-gray6">{info.paymentToken}</span>
            </div>
          </div>
        ) : null}

        <div className="ts-separator"></div>
        {Date.now() < saleState.start.getTime() ? (
          <div className="ts-countdown-labeled">
            <span>Starts in </span>
            <Countdown
              to={saleState.start}
              zeroText="Waiting for next block..."
            />
          </div>
        ) : Date.now() < saleState.end.getTime() ? (
          <div className="ts-countdown-labeled">
            <span>Ends in </span>
            <Countdown to={saleState.end} />
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className="ts-sale">
      {component}
      {state.address ? (
        <div>
          <div className="ts-separator"></div>
          <div className="ts-mb-1">
            Network:{" "}
            <b>{networkNames[state.networkId] || "Unsupported network"}</b>
          </div>
          <div className="ts-mb-1">
            Wallet: <b>{formatAddress(state.address)}</b>
          </div>
          <a onClick={() => disconnectWallet()}>Disconnect</a>
        </div>
      ) : null}
      {loading ? <div className="ts-loading-overlay">{loading}</div> : null}
      {state.walletModalOpen ? (
        <div
          className="ts-modal"
          onClick={() => setGlobalState({ walletModalOpen: false })}
        >
          <div
            className="ts-modal-content"
            style={{ width: 325, padding: "16px" }}
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="ts-wallet-option-header">Ethereum / Fantom</h3>
            <a
              onClick={() => connectWalletEthereum("metamask")}
              className="ts-wallet-option"
            >
              <img src={xdefiUrl} /> XDEFI
            </a>
            <a
              onClick={() => connectWalletEthereum("metamask")}
              className="ts-wallet-option"
            >
              <img src={metamaskUrl} /> Metamask
            </a>
            <a
              onClick={() => connectWalletEthereum("walletconnect")}
              className="ts-wallet-option"
            >
              <img src={walletconnectUrl} /> Wallet Connect
            </a>
            <h3 className="ts-wallet-option-header">Terra</h3>
            {window?.xfi?.terra ? (
              <a
                onClick={() => connectWalletTerra("terrastation")}
                className="ts-wallet-option"
              >
                <img src={xdefiUrl} /> XDEFI
              </a>
            ) : (
              <a
                onClick={() => connectWalletTerra("terrastation")}
                className="ts-wallet-option"
              >
                <img src={terrastationUrl} /> Terra Station
              </a>
            )}
            <a
              onClick={() => connectWalletTerra("terrawalletconnect")}
              className="ts-wallet-option"
            >
              <img src={walletconnectUrl} /> Wallet Connect
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
}

/*
  function returnAllocationInfo() {
    if (userInfo && info.type == "share") {
      let allocation = "None";

      if (!params.finalized && userInfo.score.gt("0")) {
        allocation = "Pending";
      }

      if (params.finalized && userInfo.score.gt("0")) {
        const capHalf = userInfo.score
          .mul(params.raising.div(2))
          .div(params.totalScore);

        allocation = formatNumber(
          bnMin(userInfo.amount, params.finalizedBaseAmount.add(capHalf)),
          info.paymentDecimalsShown,
          params.paymentDecimals
        );
      }

      return (
        <div className="flex">
          <div className="flex-1 text-gray6">Your Allocation</div>
          <div>{allocation}</div>
        </div>
      );
    }

    return null;
  }
  */

function Countdown({ to, zeroText, simple = false }) {
  const [values, setValues] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  function pad(n) {
    return ("0" + n).slice(-2);
  }

  function update() {
    let left = Math.max(0, to.getTime() - Date.now());

    const day = 1000 * 60 * 60 * 24;
    const hour = 1000 * 60 * 60;
    const minute = 1000 * 60;
    const second = 1000;

    const days = Math.floor(left / day);
    left -= days * day;
    const hours = Math.floor(left / hour);
    left -= hours * hour;
    const minutes = Math.floor(left / minute);
    left -= minutes * minute;
    const seconds = Math.floor(left / second);

    setValues({ days, hours, minutes, seconds });
  }

  useEffect(() => {
    update();
    const handle = setInterval(update, 1000);
    return () => clearInterval(handle);
  }, [to.getTime()]);

  return zeroText &&
    values.days === 0 &&
    values.hours === 0 &&
    values.minutes === 0 &&
    values.seconds === 0 ? (
    <div className="ts-countdown-warning">{zeroText}</div>
  ) : (
    <div className="ts-countdown">
      {values.days != 0 ? (
        <div className="ts-countdown-cell">
          <div className="ts-countdown-cell-number">{values.days}</div>
          <div className="ts-countdown-cell-label">days</div>
        </div>
      ) : null}
      <div className="ts-countdown-cell">
        <div className="ts-countdown-cell-number">{values.hours}</div>
        <div className="ts-countdown-cell-label">hours</div>
      </div>
      <div className="ts-countdown-cell">
        <div className="ts-countdown-cell-number">{values.minutes}</div>
        <div className="ts-countdown-cell-label">minutes</div>
      </div>
      <div className="ts-countdown-cell">
        <div className="ts-countdown-cell-number">{values.seconds}</div>
        <div className="ts-countdown-cell-label">seconds</div>
      </div>
    </div>
  );
}

function Button({ children, onClick, href, className = "", ...props }) {
  function onClickWrapper(e) {
    e.preventDefault();
    onClick(e);
  }

  if (href) {
    return (
      <a href={href} className={`ts-button ${className}`} {...props}>
        {children}
      </a>
    );
  }

  return (
    <button
      className={`ts-button ${className}`}
      onClick={onClickWrapper}
      {...props}
    >
      {children}
    </button>
  );
}
